import { useContext } from "react";
import classes from './Navigation.module.css'
import AuthContext from "../../context/auth-context";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem, ButtonGroup } from "@mui/material";
import * as React from 'react';
/**
 * THIS IS THE NAVIGATION MODULE FOR HEADER/NAV BAR. ALL THE GENERAL PURPOSE BUTTON SHOULD BE DECLARED HERE.
 */


const Navigation = () => {
  const context = useContext(AuthContext) // using the auth context
  const navigation = useNavigate()

  const logoutHandler = () => {
    alert('You will be logged out of the application. Do you still want to continue?')
    context.onLogOut()
    navigation('/login')
  }

  return (
    <div className={classes.nav}>
        {context.isLoggedIn &&
          (<ButtonGroup>
            <li>
              <NavLink to="/">
                <Button sx={{backgroundColor: '#FCA311'}}>Home</Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile">
                  <Button sx={{backgroundColor: '#FCA311'}}>My Profile</Button>
              </NavLink>
            </li>
            <li><Button sx={{ backgroundColor: '#14213d' }} onClick={logoutHandler}>Logout</Button></li>
          </ButtonGroup>)
        }
    </div>
  );
};



export default Navigation;
